import BaseCrudService from "./baseCrud.service";

export default class CouncilService extends BaseCrudService {

	constructor() {
		super('Council');
	}

	save(callback, council) {
		this.postRequest('Save', council).then(callback);
	}

	find(callback, councilId) {
		return this.getRequest('FindById', { councilId: councilId }).then(callback);
	}

	getFileByEntity(entityId) {
		return this.getRequest("GetFileByEntity", { entityId: entityId }, false, false);
	}

	findByEntityId(entityId) {
		return this.getRequest('FindByEntityId', { entityId: entityId });
	}

	listAll(callback) {
		this.getRequest("ListAll").then(callback);
	}

	loadCouncilsInformation() {
		return this.getRequest('LoadCouncilsInformation');
	}

}