<template>
  <v-menu offset-y transition="slide-y-transition" bottom left>
    <template v-slot:activator="{ on }">
      <button
        v-on="on"
        class="
          menu-overview
          elevation-base
          flex-inherit
          px-5
          v-btn--flat v-btn--large v-btn--round
        "
      >
        <Icon
          name="fal fa-bars"
          size="16"
          class="mr-2"
          colorFont="#02ACB4"
        />Menu
      </button>
    </template>
    <v-list class="options-menu-global">
      <v-list-tile v-for="(item, index) in menuNavegation" :key="index">
        <v-list-tile-title>
          <router-link :to="item.route">
            <i :class="item.icon"></i>
            {{ item.title }}
            <span>{{ item.titleCompany }}</span>
          </router-link>
        </v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script type="plain/text">
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import TokenService from "@/scripts/services/token.service";
import InstitutionService from "@/scripts/services/institution.service";
import EntityService from "@/scripts/services/entity.service";
import CompanyService from "@/scripts/services/company.service";
import CouncilService from "@/scripts/services/council.service";
import { mapGetters, mapState } from "vuex";
import Permissions from "@/scripts/models/enums/permissions.enum";
import {
  EntityType,
  EntityTypeList,
} from "@/scripts/models/enums/entityType.enum";
export default {
  created() {
    this.entityId = this.$route.query.entityId;
    this.entityService.findById(this.findEntityCallback, this.entityId);
  },
  data: () => ({
    entity: null,
    menuNavegation: [],
    institutionService: new InstitutionService(),
    companyService: new CompanyService(),
    entityService: new EntityService(),
    councilService: new CouncilService(),
    institution: null,
    company: null,
    council: null,
  }),
  computed: {
    ...mapGetters(["hasPermission"]),
  },
  methods: {
    findEntityCallback(data) {
      this.entity = data;
      if (this.entity) {
        if (this.entity.type == EntityType.Company) {
          this.companyService.findByEntityId(this.entityId).then((data) => {
            this.company = data;
            this.buildMenu();
          });
        } else if (this.entity.type == EntityType.Institution) {
          this.institutionService.findByEntityId(this.entityId).then((data) => {
            this.institution = data;
            this.buildMenu();
          });
        } else if (this.entity.type == EntityType.AUC) {
          this.companyService.findByEntityId(this.entityId).then((data) => {
            this.company = data;
            this.buildMenu();
          });
        } else if (this.entity.type == EntityType.Council) {
          this.councilService.findByEntityId(this.entityId).then((data) => {
            this.council = data;
            this.buildMenu();
          });
        }
      }
    },
    buildMenu() {
      let routeProfile = "";
      if (EntityType.Company == this.entity.type) {
        routeProfile = {
          name: Routes.app.CompanyProfile,
          query: { companyId: this.company.id, entityId: this.entity.id },
        };
      } else if (EntityType.Institution == this.entity.type) {
        routeProfile = {
          name: Routes.app.InstitutionProfile,
          query: {
            institutionId: this.institution.id,
            entityId: this.entity.id,
          },
        };
      } else if (EntityType.AUC == this.entity.type) {
        routeProfile = {
          name: Routes.app.CompanyProfile,
          query: { companyId: this.company.id, entityId: this.entity.id },
        };
      } else if (EntityType.Council == this.entity.type) {
        routeProfile = {
          name: Routes.app.CouncilProfile,
          query: { councilId: this.council.id, entityId: this.entity.id },
        };
      }

      this.menuNavegation.push({
        title: "Dashboard",
        // titleCompany: this.entity.name,
        icon: "fal fa-clipboard-list",
        route: {
          name: Routes.app.DashboardCompany,
          query: { entityId: this.entity.id },
        },
      });
      this.menuNavegation.push({
        title: "Dashboard Voluntariado",
        // titleCompany: this.entity.name,
        icon: "fal fa-clipboard-list",
        route: {
          name: Routes.app.DashboardVolunteering,
          query: { entityId: this.entity.id },
        },
      });
      this.menuNavegation.push({
        title: "Perfil",
        icon: "fal fa-user-tie",
        route: routeProfile,
      });
      this.menuNavegation.push({
        title: "Usuários",
        icon: "fal fa-user-friends",
        route: { name: Routes.app.User, query: { entityId: this.entity.id } },
      });

      if (
        EntityType.Company == this.entity.type ||
        EntityType.AUC == this.entity.type
      ) {
        this.menuNavegation.push({
          title: "Minhas Campanhas",
          icon: "fal fa-hand-receiving",
          route: {
            name: Routes.app.CampaignList,
            query: { entityId: this.entity.id, companyId: this.company.id },
          },
        });
        this.menuNavegation.push({
          title: "Indicação de doadores",
          icon: "fal fa-user-friends",
          route: {
            name: Routes.app.MemberGetMember,
            query: { entityId: this.entity.id, companyId: this.company.id },
          },
        });
        if (
          this.hasPermission(
            [Permissions.ConciliationRH, Permissions.CashFlow],
            this.entity.id
          )
        ) {
          this.menuNavegation.push({
            title: "Fluxo de Recursos",
            icon: "fal fa-envelope-open-dollar",
            route: {
              name: Routes.app.HrConciliation,
              query: { entityId: this.entity.id, companyId: this.company.id },
            },
          });
        }
      } else if (EntityType.Institution == this.entity.type) {
        this.menuNavegation.push({
          title: "Meus Projetos",
          icon: "fal fa-box-heart",
          route: {
            name: Routes.app.ProjectList,
            query: {
              entityId: this.entity.id,
              institutionId: this.institution.id,
            },
          },
        });

        this.menuNavegation.push({
          title: "Meus Projetos de Voluntariado",
          icon: "fas fa-hand-holding-heart",
          route: {
            name: Routes.app.VolunteeringList,
            query: {
              entityId: this.entity.id,
              institutionId: this.institution.id,
            },
          },
        });

        this.menuNavegation.push({
          title: "Sobre a instituição",
          icon: "fal fa-info-circle",
          route: {
            name: Routes.app.InstitutionAbout,
            query: {
              institutionId: this.institution.id,
              entityId: this.entity.id,
            },
          },
        });
        this.menuNavegation.push({
          title: "Assinatura de Recibos",
          icon: "fal fa-file-alt",
          route: {
            name: Routes.app.ManagementSignature,
            query: {
              institutionId: this.institution.id,
              entityId: this.entity.id
            }
          }
        }),
        this.menuNavegation.push({
          title: "Recibos Manuais",
          icon: "fal fa-file-alt",
          route: {
            name: Routes.app.ManagementManualSignature,
            query: {
              institutionId: this.institution.id,
              entityId: this.entity.id
            }
          }
        }); 
        if (
          this.hasPermission(
            [Permissions.ConciliationRH, Permissions.CashFlow],
            this.entity.id
          )
        ) {
          this.menuNavegation.push({
            title: "Fluxo de Recursos",
            icon: "fal fa-envelope-open-dollar",
            route: {
              name: Routes.app.PendentAcceptToInstitution,
              query: {
                entityId: this.entity.id,
                institutionId: this.institution.id,
              },
            },
          });
        }
      } else if (EntityType.Council == this.entity.type) {
        if (
          this.hasPermission(
            [
              Permissions.ConciliationRH,
              Permissions.CashFlow,
              Permissions.CanSignReceipt,
            ],
            this.entity.id
          )
        ) {
          this.menuNavegation.push({
            title: "Fluxo de Recursos",
            icon: "fal fa-envelope-open-dollar",
            route: {
              name: Routes.app.PendentAcceptToInstitution,
              query: {
                entityId: this.entity.id,
                councilId: this.council.id,
              },
            },
          });

          this.menuNavegation.push({
            title: "Assinatura de Recibos",
            icon: "fal fa-file-alt",
            route: {
              name: Routes.app.ManagementSignature,
              query: {
                entityId: this.entity.id,
              },
            },
          });
        }
      }

      // this.menuNavegation.push({
      //   title: "Fluxo de Recursos",
      //   icon: "fal fa-envelope-open-dollar",
      //   route: { name: Routes.app.DashboardCompany, entityId: this.entity.id }
      // });
      // {
      // 	title: "Visão Geral",
      // 	icon: "fal fa-chart-line",
      // },
      // {
      // 	title: "Relatório Analítico",
      // 	icon: "fal fa-table"
      // },
      // {
      // 	title: "Gestão de Folha de Pagamento",
      // 	icon: "fal fa-file-invoice-dollar"
      // }
    },
  },
};
</script>

<style>
</style>
