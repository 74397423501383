<template>
  <div>
    <HeroInner descrition="Gestão de Usuário" class="mt-70" />

    <div class="grid-box">
      <v-layout bg-white ma-4 mt-5 column br-4 elevation-base>
        <div class="my-donations-header">
          <div>
            <Icon name="fal fa-barcode-read" class="mr-4" size="32" colorFont="#C3C6D5" />
            <h2>Gestão de boletos</h2>
          </div>
          <div class="display-flex align-end">
            <InputSearch
              :infoVisible="false"
              type="text"
              place-holder="Buscar..."
              @input="applyTextSearch"
            />
            <InputSelect
              textLabel="Status"
              class="ml-4"
              :items="ticketStatusList"
              valueAttribute="value"
              textAttribute="name"
              :hasUnselectedOption="false"
              v-model="selectedTicketStatus"
              @input="filterByCartStatus"
            ></InputSelect>
          </div>
        </div>
        <v-layout px-5 py-4 row wrap>
          <template v-for="ticket in selectedTickets">
            <div class="management-bankslip-content" :key="ticket.id" v-if="canShow(ticket)">
              <div class="display-flex justify-space-between head-bankslip">
                <h3>{{ticket.cart.campaign.name}}</h3>
                <div>R$ {{formatterHelper.formatMoney(ticket.cart.totalValue)}}</div>
              </div>
              <div class="bankslip-status">
                <div
                  class="approved"
                  v-if="ticket.cart.statusId == cartStatus.Approved"
                >{{cartStatus.getName(ticket.cart.statusId)}}</div>
                <div
                  class="waiting"
                  v-if="ticket.cart.statusId == cartStatus.WaitingForApproval"
                >{{cartStatus.getName(ticket.cart.statusId)}}</div>
                <div
                  class="overdue"
                  v-if="ticket.cart.statusId == cartStatus.Expired"
                >{{cartStatus.getName(ticket.cart.statusId)}}</div>
              </div>
              <div class="display-flex">
                <div class="mr-4">
                  <div class="c-gray-dark">Número do boleto</div>
                  <div class="c-gray-dark">Vencimento do boleto</div>
                </div>
                <div>
                  <div class="c-text-dark fw-600">{{ticket.referenceCodeString}}</div>
                  <div class="c-text-dark fw-600">{{formatterHelper.formatDate(ticket.dueDate)}}</div>
                </div>
              </div>
              <div class="bankslip-status">
                <button
                  class="approved mr-2"
                  v-if="ticket.cart.statusId == cartStatus.WaitingForApproval"
                  @click="confirmChangeToApproved(ticket)"
                >Pago</button>
                <button
                  class="waiting mr-2"
                  v-if="ticket.cart.statusId != cartStatus.WaitingForApproval"
                  @click="confirmChangeToWaitingForApproval(ticket)"
                >Voltar para aprovação</button>
                <button
                  class="overdue mr-2"
                  v-if="ticket.cart.statusId == cartStatus.WaitingForApproval"
                  @click="confirmChangeToExpired(ticket)"
                >Venceu</button>
              </div>
            </div>
          </template>
          <!-- <div class="management-bankslip-content">
            <div class="display-flex justify-space-between head-bankslip">
              <h3>Minha campanha</h3>
              <div>R$ 300,00</div>
            </div>
            <div class="bankslip-status">
              <div class="approved">Aprovado</div>
              <div class="waiting">Aguardando</div>
              <div class="overdue">Vencido</div>
            </div>
            <div class="display-flex">
              <div class="mr-4">
                <div class="c-gray-dark">Número do boleto</div>
                <div class="c-gray-dark">Vencimento do boleto</div>
              </div>
              <div>
                <div
                  class="c-text-dark fw-600"
                >01010101001010010101001011010101010101010101010101000101010</div>
                <div class="c-text-dark fw-600">02/07/2020</div>
              </div>
            </div>
            <div class="bankslip-status">
              <button class="approved mr-2">Pago</button>
              <button class="waiting mr-2">Voltar para aprovação</button>
              <button class="overdue mr-2">Venceu</button>
            </div>
          </div>-->
        </v-layout>
      </v-layout>
    </div>
    <AucAlert
      ref="alertComponentChangeToApproved"
      :title="'Confirma a alteração do boleto ' + getSelectedTicketReferenceCodeString() + ' para aprovado?'"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirmedChangeToApproved()"
    />
    <AucAlert
      ref="alertComponentChangeToWaitingForApproval"
      :title="'Confirma a alteração do boleto ' + getSelectedTicketReferenceCodeString() + ' para esperando aprovação??'"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirmedChangeToWaitingForApproval()"
    />
    <AucAlert
      ref="alertComponentChangeToExpired"
      :title="'Confirma a alteração do boleto ' + getSelectedTicketReferenceCodeString() + ' para vencido?'"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirmedChangeToExpired()"
    />
  </div>
</template>
<script type="plain/text">
import HeroInner from "@/components/HeroInner.vue";
import CartService from "@/scripts/services/cart.service";
import CartStatus from "@/scripts/models/enums/cartStatus.enum.js";
import AucAlert from "@/components/AucAlert.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";

export default {
  components: {
    AucAlert,
    HeroInner
  },
  data: () => ({
    cartStatus: CartStatus,
    formatterHelper: new FormatterHelper(),
    selectedTicketStatus: CartStatus.WaitingForApproval,
    ticketStatusList: [
      { name: "Todos", value: null },
      {
        name: CartStatus.getName(CartStatus.Approved),
        value: CartStatus.Approved
      },
      {
        name: CartStatus.getName(CartStatus.WaitingForApproval),
        value: CartStatus.WaitingForApproval
      },
      {
        name: CartStatus.getName(CartStatus.Expired),
        value: CartStatus.Expired
      }
    ],
    cartService: new CartService(),
    allTickets: [],
    selectedTickets: [],
    selectedTicket: null
  }),
  created() {
    this.filterByCartStatus();
  },
  methods: {
    canShow(ticket) {
      let status = ticket.cart.statusId;
      return (
        status == CartStatus.Approved ||
        status == CartStatus.Expired ||
        status == CartStatus.WaitingForApproval
      );
    },
    filterByCartStatus() {
      this.cartService
        .listTicketDetailsByCartStatus(this.selectedTicketStatus)
        .then(this.cartService_listTicketDetailsByCartStatusCallback);
    },
    cartService_listTicketDetailsByCartStatusCallback(data) {
      this.allTickets = data;
      this.applyTextSearch();
    },
    applyTextSearch(search) {
      if (search) {
        this.selectedTickets = this.allTickets.filter(
          ticket =>
            ticket.referenceCodeString.indexOf(search) >= 0 ||
            ticket.cart.campaign.name
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
        );
      } else {
        this.selectedTickets = this.allTickets;
      }
    },
    confirmChangeToApproved(ticket) {
      this.selectedTicket = ticket;
      this.$refs.alertComponentChangeToApproved.open();
    },
    confirmChangeToWaitingForApproval(ticket) {
      this.selectedTicket = ticket;
      this.$refs.alertComponentChangeToWaitingForApproval.open();
    },
    confirmChangeToExpired(ticket) {
      this.selectedTicket = ticket;
      this.$refs.alertComponentChangeToExpired.open();
    },
    confirmedChangeToApproved() {
      this.cartService
        .changeCartStatusToApproved(this.selectedTicket)
        .then(this.filterByCartStatus);
    },
    confirmedChangeToWaitingForApproval() {
      this.cartService
        .changeCartStatusToWaitingForApproval(this.selectedTicket)
        .then(this.filterByCartStatus);
    },
    confirmedChangeToExpired() {
      this.cartService
        .changeCartStatusToExpired(this.selectedTicket)
        .then(this.filterByCartStatus);
    },
    getSelectedTicketReferenceCodeString() {
      if (this.selectedTicket) {
        return this.selectedTicket.referenceCodeString;
      }
      return "";
    }
  }
};
</script>

<style>
</style> 