<template>
  <v-layout row wrap wrap-dash>
    <div class="ficha">
      <div class="gradient-banner"></div>
      <div class="p-relative" v-if="false">
        <div class="row">
          <div class="logo-instituicao">
            <img src="../assets/images/logo_auc_pos.png" />
          </div>
        </div>
      </div>
      <v-layout row>
        <div class="content-banner">
          <div class="banner-subtitle">{{entityName}}</div>
          <h1>{{descrition}}</h1>
          <a @click="$router.app.company" class="voltar" v-if="showBackDash">
            <i class="fal fa-long-arrow-left"></i> voltar ao dashboards
          </a>
        </div>
      </v-layout>
      <Menu />
    </div>
  </v-layout>
</template>

<script type="plain/text">
import Menu from "@/components/Menu.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import EntityService from "@/scripts/services/entity.service";

export default {
  components: { Menu },
  props: {
    descrition: "",
    showBackDash: true,
    ShowLogo: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    entityService: new EntityService(),
    entityName: "Abrace uma Causa"
  }),
  created() {
    if(this.$route.query && this.$route.query.entityId) {
      this.entityService.findById(this.findEntityCallback, this.$route.query.entityId);
    }
  },
  methods: {
    findEntityCallback(data) {
      if(data && data.name) {
        this.entityName = data.name;
      }
    }
  }
};
</script>